import React, { useRef } from 'react';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';

const ActionMenu2 = ({
  onViewDetail,
  onEdit,
  onDelete,
  onUpdate,
  onAddEmployee,
  type,
}) => {
  const menuAction = useRef(null);

  let actions = [
    {
      label: 'Chỉnh sửa',
      icon: 'pi pi-pencil',
      command: onEdit,
    },
    {
      label: 'Xóa',
      icon: 'pi pi-trash',
      command: onDelete,
      style: { color: 'red', marginRight: '8px' },
    },
  ];

  return (
    <div className='flex justify-content-end align-items-center'>
      <Button
        icon='pi pi-ellipsis-v'
        onClick={(e) => menuAction.current.toggle(e)}
        // className='surface-100'
        text
      />
      <OverlayPanel ref={menuAction} dismissable>
        <div className='flex flex-column'>
          {actions.map((action, index) => (
            <Button
              key={index}
              label={action.label}
              icon={action.icon}
              onClick={action.command}
            //   className='surface-100'
              text
              style={action.style}
            />
          ))}
        </div>
      </OverlayPanel>
    </div>
  );
};

export default ActionMenu2;
