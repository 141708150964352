import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { TabMenu } from "primereact/tabmenu";
import ModalShowPlan from "./ModalShowPlan";
import ModalShowPlanLead from "./ModalShowPlanLead";
import ModalHistory from "./ModalHistory";
import "../../assets/scss/theme-base/QuantityTemplate.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../assets/scss/theme-base/QuantityTemplate.css";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import Intersection from "./intersection";
import ModalAddDashbord from "./ModalAddDashbord";
import QR from "./QR";
import {
  getOrder,
  setDataNull,
  setDataNull2,
  getBuilding,
  deleteOrder,
  exportExcel,
} from "../../store/order/slice";
import {
  statusTemplate,
  formatDate,
  QuantityTemplate,
  formatNumber,
} from "../../components/utils";
import { Avatar } from "primereact/avatar";
import { site } from "../../api/url_helper";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import LoadingSpinner from "../Report/LoadingSpinner";
import "../../assets/scss/theme-base/index.css";
import ModalImport from "./ModalImport";
import ActionMenu2 from "../../components/ActionMenu2";
import ModelDelete from "../../components/ModalDelete";
import ModalEditDashbord from "./ModalEditDashbord";
import ModalAssignment from "./ModalAssignment";
import { SplitButton } from "primereact/splitbutton";
import { exportQuantityPDF } from "./exportPDF";
import { ContextMenu } from "primereact/contextmenu";
import { Image } from "primereact/image";
import { Menu } from "primereact/menu";
import { FilterMatchMode } from "primereact/api";
import ModalShowCategoryMaterial from "./ModalShowCategoryMaterial";

export default function Order() {
  const [dialogAddVisible, setDialogAddVisible] = useState(false);
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
  const [tabMenuActive, setTabmenuActive] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [category, setCategory] = useState(null);
  const [factoryCode, setFactoryCode] = useState(null);
  const [building, setBuilding] = useState(null);
  const [orderCode, setOrderCode] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleLead, setVisibleLead] = useState(false);
  const [visibleCategoryMaterial, setVisibleCategoryMaterial] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [categoryValue, setCategoryValue] = useState("");
  const [factoryCodeValue, setFactoryCodeValue] = useState("");
  const [buildingValue, setBuildingValue] = useState();
  const [orderCodeValue, setOrderCodeValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [qrDetail, setQrDetail] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectFactoryCode, setSelectFactoryCode] = useState(null);
  const [isIntersectionVisible, setIntersectionVisible] = useState(false);
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [debounceTimeouts, setDebounceTimeouts] = useState({});
  const [dialogImportVisible, setDialogImportVisible] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [rowData, setRowData] = useState();
  const downloadLink = useRef(null);
  const [excelFileUrl, setExcelFileUrl] = useState("");
  const [assignment, setAssignment] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedIntendFinishDate, setSelectedIntendFinishDate] =
    useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedFactoryCode, setSelectedFactoryCode] = useState(null);
  const [selectedTeamWorking, setSelectedTeamWorking] = useState(null);
  const menuRight = useRef(null);
  const defaultImageUrl =
    "https://static.thenounproject.com/png/1269202-200.png";

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "Building.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
    orderCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
    unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    categoryMaterial: { value: null, matchMode: FilterMatchMode.CONTAINS },
    materialName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productNote: { value: null, matchMode: FilterMatchMode.CONTAINS },
    totalActualDelivery: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    // Thêm các trường khác nếu cần
  });

  const listPermissions =
    JSON.parse(localStorage.getItem("userPermissions")) || {};

  const {
    orderData,
    addQrData,
    productData,
    dataBuilding,
    deleteOrderData,
    errorDeleteOrder,
    editOrderData,
    exportExcelData,
    exportExcelError,
    importData,
    addHistoryData,
    updateHistoryData,
    deleteHistoryData,
    addQrTeamData,
    updatePlanCategoryMaterialData,
    deletePlanCategoryMaterialData,
    deletePlanLeadData,
    updatePlanLeadData,
    addPlanCategoryMaterialData,
    addPlanLeadData
  } = useSelector(
    (state) => ({
      orderData: state.Production.orderData,
      addQrData: state.Production.addQrData,
      dataBuilding: state.Production.dataBuilding,
      productData: state.Production.productData,
      errorDeleteOrder: state.Production.errorDeleteOrder,
      deleteOrderData: state.Production.deleteOrderData,
      editOrderData: state.Production.editOrderData,
      exportExcelData: state.Production.exportExcelData,
      exportExcelError: state.Production.exportExcelError,
      importData: state.Production.importData,
      addHistoryData: state.Production.addHistoryData,
      updateHistoryData: state.Production.updateHistoryData,
      deleteHistoryData: state.Production.deleteHistoryData,
      addQrTeamData: state.Teams.addQrTeamData,
      updatePlanCategoryMaterialData:
        state.Production.updatePlanCategoryMaterialData,
      deletePlanCategoryMaterialData:
        state.Production.deletePlanCategoryMaterialData,
      deletePlanLeadData: state.Production.deletePlanLeadData,
      updatePlanLeadData: state.Production.updatePlanLeadData,
      addPlanCategoryMaterialData: state.Production.addPlanCategoryMaterialData,
      addPlanLeadData: state.Production.addPlanLeadData,
    }),
    shallowEqual
  );

  useEffect(() => {
    let status = "Tất cả";
    if (orderData) {
      status = orderData.data.listStatus[tabMenuActive]?.label;
    }
    dispatch(
      getOrder({
        page: (first + rows) / rows,
        limit: rows,
        status,
        category: category,
        factoryCode: factoryCode,
        building: building,
        orderCode: orderCode,
        current: "plan",
      })
    );
  }, [
    dispatch,
    first,
    tabMenuActive,
    rows,
    category,
    factoryCode,
    building,
    orderCode,
  ]);

  useEffect(() => {
    if (
      updateHistoryData ||
      deleteHistoryData ||
      addHistoryData ||
      productData ||
      deleteOrderData ||
      editOrderData ||
      importData ||
      addQrTeamData ||
      updatePlanCategoryMaterialData ||
      updatePlanLeadData ||
      deletePlanLeadData ||
      deletePlanCategoryMaterialData||
      addPlanCategoryMaterialData||
      addPlanLeadData
    ) {
      dispatch(
        getOrder({
          page: (first + rows) / rows,
          limit: rows,
          current: "plan",
        })
      );
      dispatch(setDataNull2());
    }
  }, [
    deleteOrderData,
    productData,
    dispatch,
    first,
    rows,
    editOrderData,
    importData,
    addHistoryData,
    updateHistoryData,
    deleteHistoryData,
    addQrTeamData,
    updatePlanCategoryMaterialData,
    updatePlanLeadData,
    deletePlanLeadData,
    deletePlanCategoryMaterialData,
    addPlanCategoryMaterialData,
    addPlanLeadData
  ]);

  useEffect(() => {
    const updateDataTable = () => {
      if (orderData) {
        setDataTable(orderData.data.products.items);
      }
    };
    updateDataTable();
  }, [orderData]);

  useEffect(() => {
    if (isDropdownOpen) {
      dispatch(
        getBuilding({
          page: 1,
          limit: 1000,
        })
      );
    }
  }, [isDropdownOpen, dispatch]);

  useEffect(() => {
    if (dataBuilding?.data?.items) {
      const buildings = dataBuilding.data.items.map((item) => ({
        label: item.name,
        value: item.buildingCode,
      }));
      setBuildingOptions(removeDuplicates(buildings, "value"));
    }
  }, [dataBuilding]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleDetailScanned = (detail) => {
    setQrDetail(detail);
    setIsModalVisible(true);
  };

  const handleRowSelect = (rowData) => {
    setSelectedProductId(rowData.id);
    setVisible(true);
  };

  const handleAssignment = (rowData) => {
    setSelectedProductId(rowData.id);
    setSelectedCategory(rowData.categoryName);
    setSelectedIntendFinishDate(rowData.intendFinishDate);
    setSelectedFactoryCode(rowData.factoryCode);
    setSelectedTeamWorking(rowData.teamWorking);
    setAssignment(true);
  };

  const handleRowSelectLead = (rowData) => {
    setSelectedProductId(rowData.id);
    setVisibleLead(true);
  };

  const handleRowSelectCategoryMaterial = (rowData) => {
    setSelectedProductId(rowData.id);
    setVisibleCategoryMaterial(true);
  };

  const handleShowHistory = (rowData) => {
    setSelectedProductId(rowData.id);
    setSelectedFactoryCode(rowData.factoryCode);
    setVisible2(true);
  };

  const handleTeamProgress = (rowData) => {
    setSelectFactoryCode(rowData.factoryCode);
    setDialogLocationVisible(true);
    setSelectedTeamWorking(rowData.teamWorking);
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleBuildingChange = (e) => {
    setBuilding(e.target.value);
    setBuildingValue(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategoryValue(e.target.value);
    debounce(() => setCategory(e.target.value), "category");
  };

  const handleFactoryCodeChange = (e) => {
    setFactoryCodeValue(e.target.value);
    debounce(() => setFactoryCode(e.target.value), "factoryCode");
  };

  const handleOrderCodeChange = (e) => {
    setOrderCodeValue(e.target.value);
    debounce(() => setOrderCode(e.target.value), "orderCode");
  };

  const debounce = (callback, field) => {
    if (debounceTimeouts[field]) {
      clearTimeout(debounceTimeouts[field]);
    }

    const timeout = setTimeout(() => {
      callback();
      setDebounceTimeouts((prev) => ({ ...prev, [field]: null }));
    }, 400);

    setDebounceTimeouts((prev) => ({ ...prev, [field]: timeout }));
  };

  const removeDuplicates = (array, key) => {
    return array.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t[key] === item[key])
    );
  };

  const quantityTemplate = (rowData) => {
    return formatNumber(rowData.quantity);
  };

  const imageTemplate = (rowData) => {
    return (
      <Image
        src={rowData?.categoryImage || defaultImageUrl}
        alt="Category Image"
        imageStyle={{
          borderRadius: "20%",
          width: "40px",
          height: "40px",
        }}
        preview
        onError={(e) => (e.target.src = defaultImageUrl)}
      />
    );
  };

  //Sửa dữ liệu
  const handleEdit = (rowData) => {
    setRowData(rowData);
    setDialogEditVisible(true);
  };

  const handleDelete = (rowData) => {
    setModalDelete(true);
    setRowData(rowData);
  };

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.id;
      if (id !== undefined) {
        dispatch(deleteOrder({ productId: id, flag: true }));
      }
      setModalDelete(false);
    }
  };

  useEffect(() => {
    if (deleteOrderData) {
      toast.current.show({
        severity: "success",
        summary: "Thành công",
        detail: "Xóa thành công",
        life: 3000,
      });
      dispatch(setDataNull2());
    }
  }, [dispatch, deleteOrderData]);

  useEffect(() => {
    if (errorDeleteOrder) {
      toast.current.show({
        severity: "error",
        summary: "Thất bại",
        detail: errorDeleteOrder.response.data.message,
        life: 3000,
      });
      dispatch(setDataNull2());
    }
  }, [dispatch, errorDeleteOrder]);

  const actionBodyTemplate = (rowData) => {
    return listPermissions["Kế hoạch"]?.includes("Sửa thông tin") &&
      listPermissions["Kế hoạch"]?.includes("Xóa thông tin") ? (
      <ActionMenu2
        onEdit={() => handleEdit(rowData)}
        onDelete={() => handleDelete(rowData)}
      />
    ) : (
      <></>
    );
  };

  const logDataTable = () => {
    dispatch(
      exportExcel({
        type: "plan",
        list_id: dataTable.map((item) => item.id),
      })
    );
  };

  useEffect(() => {
    if (exportExcelData) {
      const url = `${site}/${exportExcelData.file}`;
      setExcelFileUrl(url);
      toast.current.show({
        severity: "success",
        summary: "Thành công",
        detail: "Xuất excel thành công.",
        life: 3000,
      });
      dispatch(setDataNull2());

      // Tự động tải file
      downloadLink.current.href = url;
      downloadLink.current.download = "exported_data.xlsx";
      downloadLink.current.click();
    }
  }, [dispatch, exportExcelData]);

  useEffect(() => {
    if (exportExcelError) {
      toast.current.show({
        severity: "error",
        summary: "Thất bại",
        detail: exportExcelError.response.data.message,
        life: 3000,
      });
      dispatch(setDataNull2());
    }
  }, [dispatch, exportExcelError]);

  const items = [];

  if (listPermissions["Kế hoạch"]?.includes("Thêm thông tin")) {
    items.push({
      label: "Nhập từ excel",
      icon: "pi pi-file-excel",
      command: () => setDialogImportVisible(true),
    });
  }

  items.push({
    label: "Xuất ra excel",
    icon: "pi pi-file-excel",
    command: logDataTable,
  });

  items.push({
    label: "In báo cáo",
    icon: "pi pi-print",
    command: () => exportQuantityPDF(dataTable),
  });

  const handleClick = (event) => {
    menuRight.current.show(event);
  };

  return (
    <>
      <Toast ref={toast} />
      <a ref={downloadLink} style={{ display: "none" }} />

      <div className="flex mb-3 gap-3 justify-content-between">
        {listPermissions["Kế hoạch"]?.includes("Thêm thông tin") && (
          <Button
            label="Thêm hạng mục"
            style={{ height: "35px" }}
            onClick={() => setDialogAddVisible(true)}
          />
        )}
        <Button
          label="Thao tác"
          popup="true"
          icon="pi pi-sort-down-fill"
          onClick={handleClick}
          aria-controls="popup_menu_right"
          aria-haspopup
          iconPos="right"
        />
        <Menu
          model={items}
          popup="true"
          ref={menuRight}
          id="popup_menu_right"
          popupAlignment="right"
        />
      </div>

      <div className="search-container pb-3">
        <IconField className="search-item">
          <Dropdown
            placeholder="Lọc công trình"
            options={buildingOptions}
            onChange={handleBuildingChange}
            value={buildingValue}
            filter
            emptyFilterMessage="Không có dữ liệu"
            emptyMessage="Không có dữ liệu"
            showClear
            className="w-full border-0"
            onClick={handleDropdownOpen}
          />
        </IconField>
        <IconField className="search-item">
          <InputText
            value={orderCodeValue}
            placeholder="Mã đơn hàng"
            className="w-full border-0"
            onChange={handleOrderCodeChange}
          />
        </IconField>
        <IconField className="search-item">
          <InputText
            value={categoryValue}
            onChange={handleCategoryChange}
            placeholder="Lọc hạng mục"
            className="w-full border-0"
          />
        </IconField>
        <IconField className="search-item">
          <InputText
            value={factoryCodeValue}
            onChange={handleFactoryCodeChange}
            placeholder="Lọc mã nhà máy"
            className="w-full border-0"
          />
        </IconField>
      </div>

      <div className="bg-white border-round-md shadow-1 px-3">
        {orderData && orderData.data ? (
          <>
            <div>
              <TabMenu
                model={orderData.data.listStatus.map((item, index) => ({
                  ...item,
                  template: () =>
                    tabMenuTemplate(
                      item,
                      index,
                      tabMenuActive,
                      setTabmenuActive
                    ),
                }))}
                activeIndex={tabMenuActive}
                onTabChange={(e) => setTabmenuActive(e.index)}
                pt={{ menuitem: "p-0" }}
              />
            </div>
            <div>
              <DataTable
                value={dataTable}
                rowGroupMode="subheader"
                groupRowsBy="Building.name"
                emptyMessage="Không có dữ liệu"
                onRowToggle={(e) => setExpandedRows(e.data)}
                size="small"
                filters={filters}
                onFilter={(e) => setFilters(e.filters)}
                scrollHeight="60vh"
                scrollable
                // filterMatchModeOptions={vietnameseFilterOptions}
                // tableStyle={{ minWidth: '70vw' }}
              >
                <Column
                  header="STT"
                  body={(rowData, options) => (
                    <div className="text-center">
                      {first + options.rowIndex + 1}
                    </div>
                  )}
                  style={{ width: "3%", minWidth: "60px" }}
                  alignHeader={"center"}
                  align={"center"}
                  frozen
                ></Column>
                <Column
                  header="Tên công trình"
                  field="Building.name"
                  style={{ width: "5%", minWidth: "200px" }}
                  filter
                  filterPlaceholder="Tìm kiếm tên CT..."
                  frozen
                  // alignHeader={"center"}
                  // align={"center"}
                ></Column>
                <Column
                  header="Mã ĐH"
                  field="orderCode"
                  style={{ width: "5%", minWidth: "100px" }}
                  filter
                  filterPlaceholder="Tìm kiếm mã ĐH..."
                  frozen
                  // alignHeader={"center"}
                  // align={"center"}
                ></Column>
                <Column
                  header="Hạng mục"
                  field="categoryName"
                  style={{ width: "8%", minWidth: "200px" }}
                  filter
                  filterPlaceholder="Tìm kiếm mã HM..."
                  frozen
                  // alignHeader={"center"}
                  // align={"center"}
                ></Column>
                <Column
                  header="Hình ảnh"
                  body={imageTemplate}
                  style={{ width: "5%", minWidth: "70px" }}
                  // alignHeader={"center"}
                  align={"center"}
                  frozen
                ></Column>
                <Column
                  header="SL"
                  field="quantity"
                  style={{ width: "3%", minWidth: "60px" }}
                  body={quantityTemplate}
                  alignHeader={"center"}
                  align={"center"}
                  frozen
                ></Column>
                <Column
                  header="ĐVT"
                  field="unit"
                  style={{ width: "3%", minWidth: "60px" }}
                  filter
                  filterPlaceholder="Tìm kiếm ĐVT..."
                  frozen
                  // alignHeader={"center"}
                  // align={"center"}
                ></Column>
                <Column
                  header="Mã SP"
                  field="productCode"
                  style={{ width: "5%", minWidth: "100px" }}
                  filter
                  filterPlaceholder="Tìm kiếm mã SP..."
                  // alignHeader={"center"}
                  // align={"center"}
                ></Column>
                <Column
                  header="Mã nhà máy"
                  field="factoryCode"
                  style={{ width: "8%", minWidth: "150px" }}
                  filter
                  filterPlaceholder="Tìm kiếm mã nhà máy..."
                  // alignHeader={"center"}
                  // align={"center"}
                ></Column>
                <Column
                  header="Vật tư chính"
                  field="materialName"
                  style={{ width: "8%", minWidth: "120px" }}
                  filter
                  filterPlaceholder="Tìm kiếm vật tư chính..."
                  // alignHeader={"center"}
                  // align={"center"}
                ></Column>
                {listPermissions["Kế hoạch"]?.includes("Sửa thông tin") && (
                  <Column
                    header="Leadtime vật tư chính"
                    body={(rowData) => (
                      <div className="text-center">
                        <i
                          className="pi pi-calendar cursor-pointer"
                          onClick={() => handleRowSelectLead(rowData)}
                        ></i>
                        <p>{formatDate(rowData.firstMaterial)}</p>
                      </div>
                    )}
                    field="keHoach"
                    style={{
                      width: "5%",
                      minWidth: "100px",
                    }}
                    alignHeader={"center"}
                    align={"center"}
                  ></Column>
                )}
                <Column
                  header="Hạng mục gia công"
                  field="categoryMaterial"
                  style={{ width: "8%", minWidth: "165px" }}
                  filter
                  filterPlaceholder="Tìm kiếm HMGC..."
                  // alignHeader={"center"}
                  // align={"center"}
                ></Column>
                {listPermissions["Kế hoạch"]?.includes("Sửa thông tin") && (
                  <Column
                    header="Leadtime hạng mục gia công"
                    body={(rowData) => (
                      <div className="text-center">
                        <i
                          className="pi pi-calendar cursor-pointer"
                          onClick={() =>
                            handleRowSelectCategoryMaterial(rowData)
                          }
                        ></i>
                        <p>{formatDate(rowData.firstMachining)}</p>
                      </div>
                    )}
                    field="keHoach"
                    style={{
                      width: "5%",
                      minWidth: "100px",
                    }}
                    alignHeader={"center"}
                    align={"center"}
                  ></Column>
                )}

                <Column
                  header="Kế hoạch hoàn thành"
                  field="intendFinishDate"
                  body={(rowData) => formatDate(rowData.intendFinishDate)}
                  style={{ width: "8%", minWidth: "175px" }}
                  alignHeader={"center"}
                  sortable
                  align={"center"}
                ></Column>
                <Column
                  header="Trạng thái"
                  field="status"
                  body={statusTemplate}
                  style={{ width: "12%", minWidth: "150px" }}
                  alignHeader={"center"}
                  filter
                  filterPlaceholder="Tìm kiếm trạng thái..."
                  align={"center"}
                ></Column>
                <Column
                  header="Tiến độ giao hàng"
                  field="totalActualDelivery"
                  body={QuantityTemplate}
                  style={{ width: "8%", minWidth: "160px" }}
                  filter
                  filterPlaceholder="Tìm kiếm tiến độ..."
                  alignHeader={"center"}
                  align={"center"}
                ></Column>
                {listPermissions["Kế hoạch"]?.includes("Sửa thông tin") && (
                  <Column
                    header="Kế hoạch"
                    body={(rowData) => (
                      <div className="text-center">
                        <i
                          className="pi pi-calendar cursor-pointer"
                          onClick={() => handleRowSelect(rowData)}
                        ></i>
                      </div>
                    )}
                    field="keHoach"
                    style={{
                      width: "5%",
                      minWidth: "100px",
                      height: "60px",
                    }}
                    alignHeader={"center"}
                    align={"center"}
                  ></Column>
                )}
                {listPermissions["Kế hoạch"]?.includes("Sửa thông tin") && (
                  <Column
                    header="Phân công sản xuất"
                    body={(rowData) => (
                      <div className="text-center">
                        <i
                          className="pi pi-calendar cursor-pointer"
                          onClick={() => handleAssignment(rowData)}
                        ></i>
                      </div>
                    )}
                    field="assignment"
                    style={{
                      width: "5%",
                      minWidth: "100px",
                    }}
                    alignHeader={"center"}
                    align={"center"}
                  ></Column>
                )}

                <Column
                  header="Lịch sử giao hàng"
                  body={(rowData) => (
                    <div className="text-center py-2">
                      <i
                        className="pi pi-calendar cursor-pointer"
                        onClick={() => handleShowHistory(rowData)}
                      ></i>
                    </div>
                  )}
                  field="keHoach"
                  style={{ width: "8%", minWidth: "80px" }}
                  alignHeader={"center"}
                  align={"center"}
                ></Column>
                <Column
                  header="Ghi chú"
                  field="productNote"
                  style={{ width: "6%", minWidth: "100px" }}
                  // alignHeader={"center"}
                  // align={"center"}
                ></Column>
                <Column
                  header="Định vị"
                  body={(rowData) => (
                    <div className="text-center">
                      <i
                        className="pi pi-map-marker cursor-pointer"
                        onClick={() => handleTeamProgress(rowData)}
                      ></i>
                      <p>{rowData.teamWorking}</p>
                    </div>
                  )}
                  style={{
                    width: "15%",
                    minWidth: "150px",
                  }}
                  alignHeader={"center"}
                  align={"center"}
                ></Column>

                <Column
                  header="In QR"
                  body={(rowData) => (
                    <div className="text-center">
                      <Button
                        onClick={() => {
                          setQrDetail(rowData);
                          setVisible3(true);
                        }}
                        icon="pi pi-qrcode"
                        rounded
                        text
                        severity="secondary"
                        aria-label="QR"
                        style={{ color: "black" }}
                      />
                    </div>
                  )}
                  style={{ width: "8%", minWidth: "100px" }}
                  alignHeader={"center"}
                  align={"center"}
                ></Column>
                <Column field="action" body={actionBodyTemplate}></Column>
                <Column />
              </DataTable>
              <div className="card">
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={orderData?.data?.products?.totalItems}
                  rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
            <ModalShowPlan
              isShow={visible}
              onHide={() => setVisible(false)}
              productId={selectedProductId}
            />
            <ModalAssignment
              isShow={assignment}
              onHide={() => setAssignment(false)}
              category={selectedCategory}
              intendFinishDate={selectedIntendFinishDate}
              factoryCode={selectedFactoryCode}
              teamWorking={selectedTeamWorking}
            />
            <ModalShowPlanLead
              isShow={visibleLead}
              onHide={() => setVisibleLead(false)}
              productId={selectedProductId}
            />
            <ModalShowCategoryMaterial
              isShow={visibleCategoryMaterial}
              onHide={() => setVisibleCategoryMaterial(false)}
              productId={selectedProductId}
            />
            <ModalHistory
              isShow={visible2}
              onHide={() => setVisible2(false)}
              productId={selectedProductId}
              factoryCode={selectedFactoryCode}
            />
            {dialogLocationVisible && (
              <Intersection
                visible={dialogLocationVisible}
                onHide={() => setDialogLocationVisible(false)}
                factoryCode={selectFactoryCode}
                teamWorking={selectedTeamWorking}
                category={selectedCategory}
              />
            )}

            <QR
              isShow={visible3}
              hiden={() => setVisible3(false)}
              detail={qrDetail}
              onDetailScanned={handleDetailScanned}
            />
            <ModalAddDashbord
              visible={dialogAddVisible}
              onHide={() => setDialogAddVisible(false)}
              toast={toast}
            />
            <ModelDelete
              visible={modalDelete}
              setVisible={setModalDelete}
              accept={acceptDelete}
              msg={
                <div style={{ textAlign: "center" }}>
                  Bạn chắc chắn muốn xóa hạng mục này?
                  <br />
                  Các thông tin liên quan đến hạng mục này sẽ bị xóa.
                </div>
              }
            />
            <ModalEditDashbord
              productId={selectedProductId}
              visible={dialogEditVisible}
              onHide={() => setDialogEditVisible(false)}
              toast={toast}
              rowData={rowData}
            />
            <ModalImport
              onHide={() => setDialogImportVisible(false)}
              visible={dialogImportVisible}
            />
          </>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </>
  );
}
