import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    productionData: null,
    productionDataByID: null,
    orderData: null,
    planData: null,
    stepData: null,
    productData: null,
    errorProductData: null,
    error: null,
    loading: false,
    dataBuilding: null,
    dataUpdate: null,
    dataTeamProgress: null,
    addPriceData: null,
    addQrData: null,
    errorQr: null,
    addPlanData: null,
    addStepData: null,
    updatePlanData: null,
    updateStepData: null,
    errorUpdateStep: null,
    deletePlanData: null,
    deleteStepData: null,
    errorDeleteStep: null,
    errorUpdatePlan: null,
    errorDeletePlan: null,
    errorAddPlan: null,
    errorStepPlan: null,
    planLeadData: null,
    planCategoryMaterialData: null,
    addPlanLeadData: null,
    addPlanCategoryMaterialData: null,
    errorAddPlanCategoryMaterial: null,
    updatePlanLeadData: null,
    updatePlanCategoryMaterialData: null,
    deletePlanLeadData: null,
    errorAddPlanLead: null,
    errorDeletePlanLead: null,
    errorUpdatePlanLead: null,
    deletePlanCategoryMaterialData: null,
    errorDeletePlanCategoryMaterial: null,
    errorUpdatePlanCategoryMaterial: null,
    teamChildrenData: null,
    excelData: null,
    errorExcelData: null,
    importData: null,
    errorImportData: null,
    errorExcelData2: null,
    excelData2: null,
    importData2: null,
    errorImportData2: null,
    excelData4: null,
    errorExcelData4: null,
    importData4: null,
    errorImportData4: null,
    deleteOrderData: null,
    errorDeleteOrder: null,
    editOrderData: null,
    errorEditOrder: null,
    exportExcelData: null,
    exportExcelError: null,
    exportExcelData2: null,
    exportExcelError2: null,
    exportExcelData4: null,
    exportExcelError4: null,
    exportExcelData5: null,
    exportExcelError5: null,
    exportExcelData3: null,
    exportExcelError3: null,
    errorImportData3: null,
    importData3: null,
    errorExcelData3: null,
    excelData3: null,
    addHistoryData: null,
    errorAddHistory: null,
    updateHistoryData: null,
    errorUpdateHistory: null,
    errorDeleteLead: null,
    errorDeleteHistory: null,
    deleteHistoryData: null,
    dataDVi: null,
    errorDataDVi: null,
};

const productionSlice = createSlice({
    name: "productions",
    initialState,
    reducers: {
        getDViFull: (state) => {
            state.loading = true;
        },
        getDViFullSuccess: (state, action) => {
            state.loading = false;
            state.dataDVi = action.payload;
            state.errorDataDVi = null;
        },
        getDViFullFail: (state, action) => {
            state.loading = false;
            state.dataDVi = null;
            state.errorDataDVi = action.payload;
        },
        getProductionAll: (state) => {
            state.loading = true;
        },
        getProductionAllSuccess: (state, action) => {
            state.loading = false;
            state.productionData = action.payload;
            state.error = null;
        },
        getProductionAllFail: (state, action) => {
            state.loading = false;
            state.productionData = null;
            state.error = action.payload;
        },
        updateProduction: (state) => {
            state.loading = true;
        },
        updateProductionSuccess: (state, action) => {
            state.loading = false;
            state.dataUpdate = action.payload;
            state.error = null;
        },
        updateProductionFail: (state, action) => {
            state.loading = false;
            state.dataUpdate = null;
            state.error = action.payload;
        },
        getOrder: (state, action) => {
            state.loading = true;
        },
        getOrderSuccess: (state, action) => {
            state.loading = false;
            state.orderData = action.payload;
            state.error = null;
        },
        getOrderFail: (state, action) => {
            state.loading = false;
            state.orderData = null;
            state.error = action.payload;
        },
        getTeamChildren: (state) => {
            state.loading = true;
        },
        getTeamChildrenSuccess: (state, action) => {
            state.loading = false;
            state.teamChildrenData = action.payload;
            state.error = null;
        },
        getTeamChildrenFail: (state, action) => {
            state.loading = false;
            state.teamChildrenData = null;
            state.error = action.payload;
        },
        getPlan: (state, action) => {
            state.loading = true;
        },
        getPlanSuccess: (state, action) => {
            state.loading = false;
            state.planData = action.payload;
            state.error = null;
        },
        getPlanFail: (state, action) => {
            state.loading = false;
            state.planData = null;
            state.error = action.payload;
        },
        getStep: (state, action) => {
            state.loading = true;
        },
        getStepSuccess: (state, action) => {
            state.loading = false;
            state.stepData = action.payload;
            state.error = null;
        },
        getStepFail: (state, action) => {
            state.loading = false;
            state.stepData = null;
            state.error = action.payload;
        },
        getPlanLead: (state, action) => {
            state.loading = true;
        },
        getPlanLeadSuccess: (state, action) => {
            state.loading = false;
            state.planLeadData = action.payload;
            state.error = null;
        },
        getPlanLeadFail: (state, action) => {
            state.loading = false;
            state.planLeadData = null;
            state.error = action.payload;
        },
        getPlanCategoryMaterial: (state, action) => {
            state.loading = true;
        },
        getPlanCategoryMaterialSuccess: (state, action) => {
            state.loading = false;
            state.planCategoryMaterialData = action.payload;
            state.error = null;
        },
        getPlanCategoryMaterialFail: (state, action) => {
            state.loading = false;
            state.planCategoryMaterialData = null;
            state.error = action.payload;
        },
        setPlan: (state, action) => {
            state.loading = true;
        },
        setPlanSuccess: (state, action) => {
            state.loading = false;
            state.addPlanData = action.payload;
            state.errorAddPlan = null;
        },
        setPlanFail: (state, action) => {
            state.loading = false;
            state.addPlanData = null;
            state.errorAddPlan = action.payload;
        },
        setHistory: (state, action) => {
            state.loading = true;
        },
        setHistorySuccess: (state, action) => {
            state.loading = false;
            state.addHistoryData = action.payload;
            state.errorAddHistory = null;
        },
        setHistoryFail: (state, action) => {
            state.loading = false;
            state.addHistoryData = null;
            state.errorAddHistory = action.payload;
        },
        setStep: (state, action) => {
            state.loading = true;
        },
        setStepSuccess: (state, action) => {
            state.loading = false;
            state.addStepData = action.payload;
            state.errorStepPlan = null;
        },
        setStepFail: (state, action) => {
            state.loading = false;
            state.addStepData = null;
            state.errorStepPlan = action.payload;
        },
        setPlanLead: (state, action) => {
            state.loading = true;
        },
        setPlanLeadSuccess: (state, action) => {
            state.loading = false;
            state.addPlanLeadData = action.payload;
            state.errorAddPlanLead = null;
        },
        setPlanLeadFail: (state, action) => {
            state.loading = false;
            state.addPlanLeadData = null;
            state.errorAddPlanLead = action.payload;
        },
        setPlanCategoryMaterial: (state, action) => {
            state.loading = true;
        },
        setPlanCategoryMaterialSuccess: (state, action) => {
            state.loading = false;
            state.addPlanCategoryMaterialData = action.payload;
            state.errorAddPlanCategoryMaterial = null;
        },
        setPlanCategoryMaterialFail: (state, action) => {
            state.loading = false;
            state.addPlanCategoryMaterialData = null;
            state.errorAddPlanCategoryMaterial = action.payload;
        },
        updatePlan: (state) => {
            state.loading = true;
        },
        updatePlanSuccess: (state, action) => {
            state.loading = false;
            state.updatePlanData = action.payload;
            state.errorUpdatePlan = null;
        },
        updatePlanFail: (state, action) => {
            state.loading = false;
            state.updatePlanData = null;
            state.errorUpdatePlan = action.payload;
        },
        updateHistory: (state) => {
            state.loading = true;
        },
        updateHistorySuccess: (state, action) => {
            state.loading = false;
            state.updateHistoryData = action.payload;
            state.errorUpdateHistory = null;
        },
        updateHistoryFail: (state, action) => {
            state.loading = false;
            state.updateHistoryData = null;
            state.errorUpdateHistory = action.payload;
        },
        updateStep: (state) => {
            state.loading = true;
        },
        updateStepSuccess: (state, action) => {
            state.loading = false;
            state.updateStepData = action.payload;
            state.errorUpdateStep = null;
        },
        updateStpeFail: (state, action) => {
            state.loading = false;
            state.updateStepData = null;
            state.errorUpdateStep = action.payload;
        },
        updatePlanLead: (state) => {
            state.loading = true;
        },
        updatePlanLeadSuccess: (state, action) => {
            state.loading = false;
            state.updatePlanLeadData = action.payload;
            state.errorUpdatePlanLead = null;
        },
        updatePlanLeadFail: (state, action) => {
            state.loading = false;
            state.updatePlanLeadData = null;
            state.errorUpdatePlanLead = action.payload;
        },
        updatePlanCategoryMaterial: (state) => {
            state.loading = true;
        },
        updatePlanCategoryMaterialSuccess: (state, action) => {
            state.loading = false;
            state.updatePlanCategoryMaterialData = action.payload;
            state.errorUpdatePlanCategoryMaterial = null;
        },
        updatePlanCategoryMaterialFail: (state, action) => {
            state.loading = false;
            state.updatePlanCategoryMaterialData = null;
            state.errorUpdatePlanCategoryMaterial = action.payload;
        },
        deletePlan: (state) => {
            state.loading = true;
        },
        deletePlanSuccess: (state, action) => {
            state.loading = false;
            state.deletePlanData = action.payload;
            state.errorDeleteLead = null;
        },
        deletetePlanFail: (state, action) => {
            state.loading = false;
            state.deletePlanData = null;
            state.errorDeleteLead = action.payload;
        },

        deleteHistory: (state) => {
            state.loading = true;
        },
        deleteHistorySuccess: (state, action) => {
            state.loading = false;
            state.deleteHistoryData = action.payload;
            state.errorDeleteHistory = null;
        },
        deleteteHistoryFail: (state, action) => {
            state.loading = false;
            state.deleteHistoryData = null;
            state.errorDeleteHistory = action.payload;
        },

        deleteStep: (state) => {
            state.loading = true;
        },
        deleteStepSuccess: (state, action) => {
            state.loading = false;
            state.deleteStepData = action.payload;
            state.errorDeleteStep = null;
        },
        deleteteStepFail: (state, action) => {
            state.loading = false;
            state.deleteStepData = null;
            state.errorDeleteStep = action.payload;
        },
        deletePlanLead: (state) => {
            state.loading = true;
        },
        deletePlanLeadSuccess: (state, action) => {
            state.loading = false;
            state.deletePlanLeadData = action.payload;
            state.errorDeletePlanLead = null;
        },
        deletetePlanLeadFail: (state, action) => {
            state.loading = false;
            state.deletePlanLeadData = null;
            state.errorDeletePlanLead = action.payload;
        },
        deletePlanCategoryMaterial: (state) => {
            state.loading = true;
        },
        deletePlanCategoryMaterialSuccess: (state, action) => {
            state.loading = false;
            state.deletePlanCategoryMaterialData = action.payload;
            state.errorDeletePlanCategoryMaterial = null;
        },
        deletePlanCategoryMaterialFail: (state, action) => {
            state.loading = false;
            state.deletePlanCategoryMaterialData = null;
            state.errorDeletePlanCategoryMaterial = action.payload;
        },
        getBuilding: (state, action) => {
            state.loading = true;
        },
        getBuildingSuccess: (state, action) => {
            state.loading = false;
            state.dataBuilding = action.payload;
            state.error = null;
        },
        getBuildingFail: (state, action) => {
            state.loading = false;
            state.dataBuilding = null;
            state.error = action.payload;
        },
        setProduct: (state) => {
            state.loading = true;
            state.productData = null;
            state.errorProductData = null;
        },
        setProductSuccess: (state, action) => {
            state.loading = false;
            state.productData = action.payload;
            state.errorProductData = null;
        },
        setProductFail: (state, action) => {
            state.loading = false;
            state.productData = null;
            state.errorProductData = action.payload;
        },
        editOrder: (state) => {
            state.loading = true;
        },
        editOrderSuccess: (state, action) => {
            state.loading = false;
            state.editOrderData = action.payload;
            state.errorEditOrder = null;
        },
        editOrderFail: (state, action) => {
            state.loading = false;
            state.editOrderData = null;
            state.errorEditOrder = action.payload;
        },
        getTeamProgress: (state, action) => {
            state.loading = true;
        },
        getTeamProgressSuccess: (state, action) => {
            state.loading = false;
            state.dataTeamProgress = action.payload;
            state.error = null;
        },
        getTeamProgressFail: (state, action) => {
            state.loading = false;
            state.dataTeamProgress = null;
            state.error = action.payload;
        },
        addPrice: (state) => {
            state.loading = true;
        },
        addPriceSuccess: (state, action) => {
            state.loading = false;
            state.addPriceData = action.payload;
            state.errorPrice = null;
        },
        addPriceFail: (state, action) => {
            state.loading = false;
            state.addPriceData = null;
            state.errorPrice = action.payload;
        },
        addQr: (state) => {
            state.loading = true;
            state.addQrData = null;
            state.errorQr = null;
        },
        addQrSuccess: (state, action) => {
            state.loading = false;
            state.addQrData = action.payload;
            state.errorQr = null;
        },
        addQrFail: (state, action) => {
            state.loading = false;
            state.addQrData = null;
            state.errorQr = action.payload;
        },
        downloadExcel: (state, action) => {
            state.loading = true;
        },
        downloadExcelSuccess: (state, action) => {
            state.loading = false;
            state.excelData = action.payload;
            state.errorExcelData = null;
        },
        downloadExcelFail: (state, action) => {
            state.loading = false;
            state.excelData = null;
            state.errorExcelData = action.payload;
        },
        downloadExcel2: (state, action) => {
            state.loading = true;
        },
        downloadExcel2Success: (state, action) => {
            state.loading = false;
            state.excelData2 = action.payload;
            state.errorExcelData2 = null;
        },
        downloadExcel2Fail: (state, action) => {
            state.loading = false;
            state.excelData2 = null;
            state.errorExcelData2 = action.payload;
        },
        downloadExcel3: (state, action) => {
            state.loading = true;
        },
        downloadExcel3Success: (state, action) => {
            state.loading = false;
            state.excelData3 = action.payload;
            state.errorExcelData3 = null;
        },
        downloadExcel3Fail: (state, action) => {
            state.loading = false;
            state.excelData3 = null;
            state.errorExcelData3 = action.payload;
        },
        downloadExcel4: (state, action) => {
            state.loading = true;
        },
        downloadExcel4Success: (state, action) => {
            state.loading = false;
            state.excelData4 = action.payload;
            state.errorExcelData4 = null;
        },
        downloadExcel4Fail: (state, action) => {
            state.loading = false;
            state.excelData4 = null;
            state.errorExcelData4 = action.payload;
        },
        importExcelData: (state, action) => {
            state.loading = true;
        },
        importExcelDataSuccess: (state, action) => {
            state.loading = false;
            state.errorImportData = null;
            console.log(action.payload.successOrder);
            state.importData = action.payload;
        },
        importExcelDataFail: (state, action) => {
            state.loading = false;
            state.errorImportData = action.payload;
            state.importData = null;
        },
        importExcelData2: (state, action) => {
            state.loading = true;
        },
        importExcelData2Success: (state, action) => {
            state.loading = false;
            state.errorImportData2 = null;
            state.importData2 = action.payload;
        },
        importExcelData2Fail: (state, action) => {
            state.loading = false;
            state.errorImportData2 = action.payload;
            state.importData2 = null;
        },
        importExcelData3: (state, action) => {
            state.loading = true;
        },
        importExcelData3Success: (state, action) => {
            state.loading = false;
            state.errorImportData3 = null;
            state.importData3 = action.payload;
        },
        importExcelData3Fail: (state, action) => {
            state.loading = false;
            state.errorImportData3 = action.payload;
            state.importData3 = null;
        },
        importExcelData4: (state, action) => {
            state.loading = true;
        },
        importExcelData4Success: (state, action) => {
            state.loading = false;
            state.errorImportData4 = null;
            state.importData4 = action.payload;
        },
        importExcelData4Fail: (state, action) => {
            state.loading = false;
            state.errorImportData4 = action.payload;
            state.importData4 = null;
        },
        deleteOrder: (state) => {
            state.loading = true;
        },
        deleteOrderSuccess: (state, action) => {
            state.loading = false;
            state.deleteOrderData = action.payload;
            state.errorDeleteOrder = null;
        },
        deleteOrderFail: (state, action) => {
            state.loading = false;
            state.deleteOrderData = null;
            state.errorDeleteOrder = action.payload;
        },
        exportExcel: (state) => {
            state.loading = true;
        },
        exportExcelSuccess: (state, action) => {
            state.loading = false;
            state.exportExcelData = action.payload;
            state.exportExcelError = null;
        },
        exportExcelFail: (state, action) => {
            state.loading = false;
            state.exportExcelData = null;
            state.exportExcelError = action.payload;
        },
        exportExcel2: (state) => {
            state.loading = true;
        },
        exportExcel2Success: (state, action) => {
            state.loading = false;
            state.exportExcelData2 = action.payload;
            state.exportExcelError2 = null;
        },
        exportExcel2Fail: (state, action) => {
            state.loading = false;
            state.exportExcelData3 = null;
            state.exportExcelError3 = action.payload;
        },
        exportExcel3: (state) => {
            state.loading = true;
        },
        exportExcel3Success: (state, action) => {
            state.loading = false;
            state.exportExcelData3 = action.payload;
            state.exportExcelError3 = null;
        },
        exportExcel3Fail: (state, action) => {
            state.loading = false;
            state.exportExcelData3 = null;
            state.exportExcelError3 = action.payload;
        },
        exportExcel4: (state) => {
            state.loading = true;
        },
        exportExcel4Success: (state, action) => {
            state.loading = false;
            state.exportExcelData4 = action.payload;
            state.exportExcelError4 = null;
        },
        exportExcel4Fail: (state, action) => {
            state.loading = false;
            state.exportExcelData4 = null;
            state.exportExcelError4 = action.payload;
        },
        exportExcel5: (state) => {
            state.loading = true;
        },
        exportExcel5Success: (state, action) => {
            state.loading = false;
            state.exportExcelData5 = action.payload;
            state.exportExcelError5 = null;
        },
        exportExcel5Fail: (state, action) => {
            state.loading = false;
            state.exportExcelData5 = null;
            state.exportExcelError5 = action.payload;
        },

        setDataNull: (state) => {
            // state.dataBuilding = null;
            state.dataUpdate = null;
            state.orderData = null;
            state.addQrData = null;
            state.errorQr = null;
        },
        setDataNull2: (state) => {
            state.deletePlanCategoryMaterialData = null;
            state.errorDeletePlanCategoryMaterial = null;
            state.updatePlanCategoryMaterialData = null;
            state.errorUpdatePlanCategoryMaterial = null;
            state.addPlanCategoryMaterialData = null;
            state.errorAddPlanCategoryMaterial = null;
            state.errorDeleteLead = null;
            state.productData = null;
            state.errorProductData = null;
            state.addPlanData = null;
            state.updatePlanData = null;
            state.deletePlanData = null;
            state.errorUpdatePlan = null;
            state.errorDeletePlan = null;
            state.errorAddPlan = null;
            state.addPlanLeadData = null;
            state.updatePlanLeadData = null;
            state.deletePlanLeadData = null;
            state.errorAddPlanLead = null;
            state.errorDeletePlanLead = null;
            state.errorUpdatePlanLead = null;
            state.planData = null;
            state.planLeadData = null;
            state.planCategoryMaterialData = null;
            state.addPriceData = null;
            state.errorImportData = null;
            state.importData = null;
            state.excelData = null;
            state.errorExcelData = null;
            state.excelData2 = null;
            state.errorExcelData2 = null;
            state.importData2 = null;
            state.errorImportData2 = null;
            state.excelData4 = null;
            state.errorExcelData4 = null;
            state.importData4 = null;
            state.errorImportData4 = null;
            state.deleteOrderData = null;
            state.errorDeleteOrder = null;
            state.editOrderData = null;
            state.errorEditOrder = null;
            state.exportExcelData = null;
            state.exportExcelError = null;
            state.exportExcelData2 = null;
            state.exportExcelError2 = null;
            state.exportExcelData4 = null;
            state.exportExcelError4 = null;
            state.exportExcelData5 = null;
            state.exportExcelError5 = null;
            state.exportExcelData3 = null;
            state.exportExcelError3 = null;
            state.stepData = null;
            state.addStepData = null;
            state.updateStepData = null;
            state.errorUpdateStep = null;
            state.deleteStepData = null;
            state.errorDeleteStep = null;
            state.errorStepPlan = null;
            state.errorPrice = null;
            state.importData3 = null;
            state.errorImportData3 = null;
            state.excelData3 = null;
            state.errorExcelData3 = null;
            state.addHistoryData = null;
            state.errorAddHistory = null;
            state.updateHistoryData = null;
            state.errorUpdateHistory = null;
            state.errorDeleteHistory = null;
            state.deleteHistoryData = null;
        },
        resetTeamProgress(state) {
            state.dataTeamProgress = null;
            state.dataDVi = null;
        },
        resetTeamChildren(state) {
            state.teamChildrenData = null;
        },
    },
});

export const {
    deletePlanCategoryMaterial,
    deletePlanCategoryMaterialSuccess,
    deletePlanCategoryMaterialFail,
    updatePlanCategoryMaterial,
    updatePlanCategoryMaterialSuccess,
    updatePlanCategoryMaterialFail,
    setPlanCategoryMaterial,
    setPlanCategoryMaterialSuccess,
    setPlanCategoryMaterialFail,
    getPlanCategoryMaterial,
    getPlanCategoryMaterialSuccess,
    getPlanCategoryMaterialFail,
    getProductionAll,
    getProductionAllSuccess,
    getProductionAllFail,
    updateProduction,
    updateProductionFail,
    updateProductionSuccess,
    getOrder,
    getOrderSuccess,
    getOrderFail,
    getPlan,
    getPlanSuccess,
    getPlanFail,
    setPlan,
    setPlanSuccess,
    setPlanFail,
    getBuilding,
    getBuildingSuccess,
    getBuildingFail,
    setProduct,
    setProductSuccess,
    setProductFail,
    getTeamProgress,
    getTeamProgressSuccess,
    getTeamProgressFail,
    addPrice,
    errorPrice,
    addPriceSuccess,
    addPriceFail,
    addQr,
    addQrSuccess,
    addQrFail,
    setDataNull,
    setDataNull2,
    resetTeamProgress,
    updatePlan,
    updatePlanSuccess,
    updatePlanFail,
    deletePlan,
    deletePlanSuccess,
    deletetePlanFail,
    getPlanLead,
    getPlanLeadSuccess,
    getPlanLeadFail,
    setPlanLead,
    setPlanLeadSuccess,
    setPlanLeadFail,
    updatePlanLead,
    updatePlanLeadSuccess,
    updatePlanLeadFail,
    deletePlanLead,
    deletePlanLeadSuccess,
    deletetePlanLeadFail,
    getTeamChildren,
    getTeamChildrenSuccess,
    getTeamChildrenFail,
    resetTeamChildren,
    downloadExcel,
    downloadExcelFail,
    downloadExcelSuccess,
    importExcelData,
    importExcelDataSuccess,
    importExcelDataFail,
    downloadExcel2,
    downloadExcel2Success,
    downloadExcel2Fail,
    importExcelData2,
    importExcelData2Success,
    importExcelData2Fail,
    importExcelData4,
    importExcelData4Success,
    importExcelData4Fail,
    downloadExcel4,
    downloadExcel4Success,
    downloadExcel4Fail,
    deleteOrder,
    deleteOrderSuccess,
    deleteOrderFail,
    editOrder,
    editOrderSuccess,
    editOrderFail,
    exportExcel,
    exportExcelSuccess,
    exportExcelFail,
    exportExcel2,
    exportExcel2Success,
    exportExcel2Fail,
    exportExcel3,
    exportExcel3Success,
    exportExcel3Fail,
    exportExcel4,
    exportExcel4Success,
    exportExcel4Fail,
    exportExcel5,
    exportExcel5Success,
    exportExcel5Fail,
    getStep,
    getStepSuccess,
    getStepFail,
    setStep,
    setStepSuccess,
    setStepFail,
    updateStep,
    updateStepSuccess,
    updateStepFail,
    deleteStep,
    deleteStepSuccess,
    deleteteStepFail,
    importExcelData3,
    importExcelData3Success,
    importExcelData3Fail,
    downloadExcel3,
    downloadExcel3Success,
    downloadExcel3Fail,
    setHistory,
    setHistorySuccess,
    setHistoryFail,
    updateHistory,
    updateHistorySuccess,
    updateHistoryFail,
    deleteHistory,
    deleteHistorySuccess,
    deleteHistoryFail,
    getDViFull,
    getDViFullSuccess,
    getDViFullFail,
} = productionSlice.actions;
export default productionSlice.reducer;
